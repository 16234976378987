import { Box } from "@mybridge/ui/layout"
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@mybridge/ui/popover"

export const InsightPopover = ({ children, content, wrapperProps, ...props }) => {
    return <Popover
        placement="top"
        trigger="hover"
        {...(wrapperProps ?? {})}
    >
        <PopoverTrigger>
            <Box>
                {children}
            </Box>
        </PopoverTrigger>
        <PopoverContent
            w="auto"
            minW="20"
            bg="blackAlpha.800"
            border="none"
        >
            <PopoverArrow bg="blackAlpha.800" />
            <PopoverBody
                bg="transparent"
                color="white"
                fontSize="sm"
                {...props}
            >
                {content}
            </PopoverBody>
        </PopoverContent>
    </Popover>
}